const AtencionUsuario = () => {
    return (
        <div className="content-body">
            <div className="card">
                <div className="card-content">
                    <div className="card-body mt-1">
                        <div className='text-center'>
                            <p className='texto'>Atención Usuario</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AtencionUsuario;