import React, {useEffect, useState} from "react";
import FloatingButton from "../components/floating_button";
import { GetToken, GetAttributes, GenerateImage } from "../redux/disenar_joya";
import Input from '../components/input';
import SelectInput from '../components/select';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "../components/loading-spinner";


const DisenarJoya = () => {
    const [token, setToken] = useState(null);
    const [attributes, setAttributes] = useState(null);
    const [description, setDescription] = useState('');
    const [datas, setDatas] = useState([]);
    const [url_image, setUrlImage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    async function get_attributes () {
        const response = await GetAttributes(token);
        if (response.status === 200) {
            setDatas(response.data.list_attributes);
            let attrib = []
            // eslint-disable-next-line
            response.data.list_attributes.map(element => {
                attrib.push({
                    "id": element.id,
                    "name": element.name,
                    "option_id": null
                })
            });
            setAttributes(attrib);
        }
    }

    async function get_token () {
        const response = await GetToken();
        if (response.status === 200) {
            setToken(response.data.token);
            get_attributes(response.data.token);
        }
    }

    async function generate_image (values) {
        const response = await GenerateImage(token, values);
        console.log(response);
        if (response.status === 200 && response.data && response.data.response.http_status === 200) {
            setUrlImage(response.data.response.image_url);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        get_token();
        // eslint-disable-next-line
    }, [token]);

    const handleChange = (e, id) => {
        // eslint-disable-next-line
        attributes.map(x => {
            if(x.id === id) {
                x.option_id = parseInt(e.target.value);
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        let message = 'Debe seleccionar el ';
        let valid = true;
        // eslint-disable-next-line
        attributes.map(a => {
            if (a.option_id === null) {
                valid = false;
                message =  message + a.name + ' '; 
            }
        })
        if (valid === false) {
            toast.error(message);
            setIsLoading(false);
            return;
        }
        if (description === "") {
            toast.error("Debe ingresar una descripción");
            setIsLoading(false);
            return;
        }
        let value = {
            "attributes": attributes,
            "description": description,
            "system_name": "Sindri EC",
            "client_id": "1"
        };
        generate_image(value);
    }

    function clear_value() {
        setUrlImage('');
        setDescription('');
        setDatas([]);
        get_attributes();
    }

    return (
        <div className="content-body">
            <div className="card">
                <ToastContainer/>
                {isLoading && <LoadingSpinner/>}
                <div className="card-content">
                    <div className="card-body mt-1">
                        <div className='text-center mb-2'>
                            <h3 className='texto'>FABRICACIÓN DE JOYA PERSONALIZADA</h3>
                        </div>
                        <div className="row mt-2">
                            <div className="offset-lg-4 col-lg-4 offset-md-3 col-md-6 col-xs-12 mt-1" style={{background: '#FFF', borderRadius: 20, width: '100%', height: '80%'}}>
                                <form className="card" onSubmit={handleSubmit}>
                                    <div className="card-body">
                                        <div className="text-right">
                                            <button type="button" className="btn btn-danger" onClick={() => clear_value()}>Limpiar campos</button>
                                        </div>
                                        <h5>Complete los campos</h5>
                                        <div className="row">
                                            {datas.map(x => (
                                                <div className="col-12">
                                                    <div className="form-group">
                                                    <label>{x.name}</label>
                                                    <SelectInput
                                                        label={x.name}
                                                        name={x.name}
                                                        onChange={(e) => handleChange(e, x.id)}
                                                        options={x.options_list}
                                                />
                                            </div>
                                                </div>
                                            ))}
                                            <div className="col-12">
                                                <Input
                                                    label="Descripción"
                                                    name="descripcion"
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    value={description}
                                                />
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-success">Generar imagen</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-12 col-xs-12 text-center">
                                {url_image &&
                                    <img className='mt-1' style={{width: '80%', height: '75%'}}
                                        src={url_image}
                                        alt="img"
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FloatingButton/>
        </div>
    );
}

export default DisenarJoya;