import { useNavigate } from "react-router-dom";

const Index = (props) => {
    let navigate = useNavigate();

    return (
        <div className="content-body">
            <div className="card">
                <div className="card-content">
                    <div className="card-body mt-1">
                        <div className='text-center pb-md-3 pb-sm-2'>
                            <img className='mt-2 img_2'
                                src={window.location.origin + "/base/app-assets/images/logo/isotipo.png"}
                                alt="img"
                            />
                        </div>
                        <div className='row'>
                            <div className="col-sm-6 col-12">
                            <div className='div_card mt-1 pb-2 text-center' onClick={() => {navigate("/pagar-renovacion");}}>
                                <div className="div_image">
                                    <img className='mt-2 img_3'
                                        src={window.location.origin + "/base/app-assets/images/logo/Contrato.png"}
                                        alt="img"
                                    />
                                </div>
                                <h5 style={{fontSize: 16, color: '#FFF', fontWeight: 700, marginTop: 10}}>RETIRAR O RENOVAR CONTRATO</h5>
                            </div>
                            </div>
                            <div className="col-sm-6 col-12">
                            <div className='div_card mt-1 pb-2 text-center' onClick={() => {navigate("/comprar-joya");}}>
                                <img className='mt-2 img_3'
                                    src={window.location.origin + "/base/app-assets/images/logo/web.png"}
                                    alt="img"
                                />
                                <h5 style={{fontSize: 16, color: '#FFF', fontWeight: 700, marginTop: 10}}>BUSCAR O COMPRAR PRODUCTOS</h5>
                            </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-sm-6 col-12">
                                <div className='div_card mt-1 pb-2 text-center' onClick={() => {navigate("/disenar-joya");}}>
                                <img className='mt-2 img_3'
                                    src={window.location.origin + "/base/app-assets/images/logo/Fabricacion.png"}
                                    alt="img"
                                />
                                <h5 style={{fontSize: 16, color: '#FFF', fontWeight: 700, marginTop: 10}}>FABRICACIÓN DE JOYA PERSONALIZADA</h5>
                                </div>
                            </div>
                            <div className="col-sm-6 col-12">
                            <div className='div_card mt-1 pb-2 text-center' onClick={() => {}}>
                                <img className='mt-2 img_3'
                                    src={window.location.origin + "/base/app-assets/images/logo/Ticket.png"}
                                    alt="img"
                                />
                                <h5 style={{fontSize: 16, color: '#FFF', fontWeight: 700, marginTop: 10}}>GENERAR TICKET</h5>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;