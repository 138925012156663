import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from './pages/index'
import PagarRenovacion from './pages/pagar_renovacion'
import ComparJoya from './pages/comprar_joya'
import DisenarJoya from './pages/disenar_joya'
import AtencionUsuario from './pages/atencion_usuario'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/pagar-renovacion" element={<PagarRenovacion />} />
        <Route path="/comprar-joya" element={<ComparJoya />} />
        <Route path="/disenar-joya" element={<DisenarJoya />} />
        <Route path="/atencion-usuario" element={<AtencionUsuario />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
