import axios from "axios";
import { USERNAME, PASSWORD, URL_TOKEN, URL_GET_ATTRIBUTES, URL_GENERATE_IMAGE } from "../config";

export const GetToken = async () => {
    try {
        let data = JSON.stringify({"username": USERNAME,"password": PASSWORD})
        const res = await axios.post(URL_TOKEN, data, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
        });
        return res;
    } catch (e) {
        return {status: 500};
    }
};



export const GetAttributes = async (token) => {
    try {
        const res = await axios.get(URL_GET_ATTRIBUTES, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token
            },
        });
        return res;
    } catch(e) {
        return {status: 500};
    }
};

export const GenerateImage = async (token, data) => {
    try {
        const res = await axios.post(URL_GENERATE_IMAGE, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token
            },
        });
        return res;
    } catch(e) {
        return {status: 500};
    }
};