import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";

const FloatingButton = () => {
    let navigate = useNavigate();
    return (
        <div class="text-right">
            {/* eslint-disable-next-line */}
            <a href="" class="floating-button" onClick={() => {navigate('/');}}
            title="Inicio"><i class="bx bxs-home floating-button-icon"></i></a>
        </div>
    );
};
export default FloatingButton;