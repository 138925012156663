import FloatingButton from "../components/floating_button";
import { URL_PAGAR_RENOVACION } from "../config";

const PagarRenovacion = (props) => {
    return (
        <div className="content-body">
            <div className="card">
                <div className="card-content">
                    <div className="card-body mt-1">
                        <div className='text-center'>
                            {/* eslint-disable-next-line */}
                            <iframe src={URL_PAGAR_RENOVACION} width="100%" height="1200" allow="fullscreen"/>
                        </div>
                    </div>
                </div>
            </div>
            <FloatingButton/>
        </div>
    );
}

export default PagarRenovacion;